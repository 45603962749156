<template>
  <div class="va-navbar__item">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VaNavbarItem',
}
</script>
