<template>
  <div class="grid row flex-center mt-3">
    <va-card class="flex xs11 md11">
      <va-card-title style="font-size: 24pt">My todos</va-card-title>
      <va-card-content>
        <div class="flex xs12 md12">
          <div class="row flex">
            <va-card class="mb-5">
              <va-card-title> Add new</va-card-title>
              <va-card-content class="row flex">
                <form @submit.prevent="addTodo" class="grid row">
                  <va-input
                      class="flex xs4 md4 my-2 mx-2"
                      label="Title"
                      v-model="newTodo.title"
                  />
                  <va-select
                      class="flex xs2 md2 my-2 mx-2"
                      v-model="newTodo.priority"
                      :options="priorityOptions"
                      value-by="value"
                      text-by="title"
                      label="Priority"
                  />
                  <va-checkbox
                      class="flex xs2 md2 my-auto mx-2 align--end justify--end"
                      v-model="newTodo.finished"
                      label="Finished"
                  />
                  <va-input
                      class="flex md12 xs12 my-2 mx-2"
                      label="Description"
                      v-model="newTodo.description"
                  />
                  <va-button type="submit" icon="done">Add</va-button>
                </form>
              </va-card-content>
            </va-card>
          </div>
          <div class="row flex-center todo-item">
            <va-card
                color="background" gradient
                class="flex xs3 md3 mx-1 my-1 align-self--baseline"
                v-for="todo in todos"
                :key="todo.id"
                :stripe="todo.finished"
                stripe-color="success"
                @dblclick="todo.finished = !todo.finished"
                style="user-select: none"
            >
              <va-card-title>
                {{ todo.title }}
              </va-card-title>
              <va-card-content>
                <div>{{ todo.description }}</div>
                <div class="my-3">
                  <va-rating v-model="todo.priority" color="secondary"/>
                </div>
              </va-card-content>
            </va-card>
          </div>
        </div>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
export default {
  name: "VTodos",
  data() {
    return {
      newTodo: {
        id: null,
        title: "",
        description: "",
        priority: 3,
        finished: false,
      },
      priorityOptions: [
        {title: "negligible", value: 1},
        {title: "not important", value: 2},
        {title: "important", value: 3},
        {title: "very important", value: 4},
        {title: "must do asap", value: 5},
      ],
      todos: [
        {
          id: 1,
          title: "Dishes",
          description: "I must do the dishes",
          priority: 4,
          finished: true,
        },
        {
          id: 2,
          title: "Homework",
          description: "I got some homework to do",
          priority: 4,
          finished: false,
        },
        {
          id: 3,
          title: "Test",
          description: "Tomorrow i will have a vue test",
          priority: 5,
          finished: false,
        },
        {
          id: 4,
          title: "Programming",
          description: "I will practice programming in my free time",
          priority: 1,
          finished: false,
        },
      ],
    };
  },
  methods: {
    addTodo() {
      this.todos.push(this.newTodo);
      this.newTodo = {
        id: null,
        title: "",
        description: "",
        priority: 3,
        finished: false,
      };
    },
  },
};
</script>

<style>
.todo-item {
  align-items: stretch;
  display: flex;
}
</style>